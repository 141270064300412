@import "../../../styles/config";

.container {
  width: 100%;
  border: 0;
  padding: 0;
  padding-bottom: $grid-unit-30;
  &.disableHelperTextSpace {
    padding-bottom: $grid-unit-05;
  }
  &.hasMessage {
    padding-bottom: $grid-unit-05;
    .description {
      margin-top: 0;
    }
  }
  &.error {
    .helperText {
      color: $wild-watermellon-600 !important;
    }
  }
  &.required {
    .label::after {
      content: " *";
    }
  }
  &.disabled {
    .label {
      opacity: 0.6;
    }
    .helperText {
      opacity: 0.6;
    }
  }
  &.small {
    .label {
      @include body-sm;
    }
    .helperText {
      @include body-sm;
    }
  }
  &.hideLabel {
    .label {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  }
  &.hideHelperText {
    margin-bottom: 0;
    .helperText {
      position: absolute;
      opacity: 0;
    }
  }
  .label {
    @include body-md;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: $wolf-800;
    margin-bottom: $grid-unit-025;
    margin-left: $grid-unit-025;
  }
  .helperText {
    @include body-md;
    display: block;
    min-height: $grid-unit-25;
    color: $wolf-600;
  }
  .description {
    @include body-lg;
    font-style: italic;
    display: block;
    width: 100%;
    color: $wolf-500;
    margin-top: $grid-unit;
    margin-left: $grid-unit-20;
  }
}
